<template>
    <v-overlay v-if="isFetching">
        <v-img src="/media/loader/loading.gif" width="300px" height="300px" style="margin:0 auto;" />
    </v-overlay>
    <div v-else>
        <v-card class="report-card report-card--header">
            <div class="d-flex align-items-end justify-space-between">
                <div style="flex-grow:1;">
                    <v-img :src="seller.logo" width="135px" />
                </div>
                <div class="report-card__address" style="flex-grow:1;">
                    <div>{{ seller.address }}</div>
                    <div>{{ seller.zipCode }}, {{ seller.city }}</div>
                </div>
                <div class="report-card__address" style="flex-grow:1;">
                    <div>{{ email }}</div>
                </div>
                <div style="flex-grow:2; text-align:right;">
                    <v-btn class="boton" @click="openPrint">
                        <v-icon class="mr-1">mdi-printer</v-icon>
                        Imprimir
                    </v-btn>
                </div>
            </div>
        </v-card>

        <v-card class="report-card report-card--content">
            <div class="report-card__title">
                Reporte de ventas
            </div>
            <div class="report-card__separator" />

            <v-row v-if="report.prodEstrella" class="report-card__line">
                <v-col cols="4">
                    Producto estrella
                </v-col>
                <v-col cols="4" class=" text-center ">
                    {{ report.prodEstrella.Producto }}
                </v-col>
                <v-col cols="4" class=" text-right">
                    Vendido {{ report.prodEstrella.Suma }} veces
                </v-col>
            </v-row>
            <div v-if="report.prodEstrella" class="report-card__separator" />

            <v-row v-if="report.Deal" class="report-card__line">
                <v-col cols="4">
                    Oferta estrella
                </v-col>
                <v-col cols="4" class=" text-center ">
                    {{ report.Deal._id }}
                </v-col>
                <v-col cols="4" class=" text-right">
                    Vendido {{ report.Deal.Cuenta }} veces
                </v-col>
            </v-row>
            <div v-if="report.Deal" class="report-card__separator" />

            <v-row v-if="report.DealProduct" class="report-card__line">
                <v-col cols="4">
                    Producto más vendido en ofertas
                </v-col>
                <v-col cols="4" class=" text-center ">
                    {{ report.DealProduct.name }}
                </v-col>
                <v-col cols="4" class=" text-right">
                    Vendido {{ report.DealProduct.Suma }} veces
                </v-col>
            </v-row>
            <div v-if="report.DealProduct" class="report-card__separator" />

            <v-row v-if="report.precioMedio" class="report-card__line">
                <v-col cols="4">
                    Pedido medio
                </v-col>
                <v-col cols="4" class="text-center">
                    {{ report.precioMedio | formatPrice }} €
                </v-col>
                <v-col cols="4"> </v-col>
            </v-row>
            <div v-if="report.precioMedio" class="report-card__separator" />

            <v-row v-if="report.codPostal" class="report-card__line">
                <v-col cols="4">
                    CP más atendido
                </v-col>
                <v-col cols="4" class="text-center">
                    {{ report.codPostal._id }}
                </v-col>
                <v-col cols="4" class="text-right">
                    {{ report.codPostal.Suma }} pedidos
                </v-col>
            </v-row>
            <div v-if="report.codPostal" class="report-card__separator" />

            <v-row v-if="report.user" class="report-card__line">
                <v-col cols="4">
                    Usuario más atendido
                </v-col>
                <v-col cols="4" class="text-center">
                    {{ report.user.email }}
                </v-col>
                <v-col cols="4" class="text-right">
                    {{ report.user.suma }} pedidos
                </v-col>
            </v-row>
            <div v-if="report.user" class="report-card__separator" />

            <v-row v-if="report.costeMedioEnvio" class="report-card__line">
                <v-col cols="4">
                    Gasto medio reparto
                </v-col>
                <v-col cols="4" class="text-center">
                    {{ report.costeMedioEnvio | formatPrice }} €
                </v-col>
                <v-col cols="4" class="text-right">
                </v-col>
            </v-row>
            <div v-if="report.costeMedioEnvio" class="report-card__separator" />

            <v-row v-for="reparto in report.reparto" :key="reparto._id" class="report-card__line">
                <v-col cols="4">
                    Reparto por {{ reparto._id | capitalize }}
                </v-col>
                <v-col cols="4" class="text-center">
                    <!-- {{report.reparto[0].Suma}} € -->
                </v-col>
                <v-col v-if="report.reparto[0].Coste" cols="4" class="text-right">
                    Te esta saliendo a {{ reparto.Coste | formatPrice }} €
                </v-col>
            </v-row>
            <div v-if="report.reparto[0]" class="report-card__separator" />

            <v-row v-if="report.pedidosTipo[0] && report.pedidosTipo[0]._id === 'takeaway'" class="report-card__line">
                <v-col cols="4">
                    Pedidos a recoger en local
                </v-col>
                <v-col cols="4" class="text-center">
                    {{ report.pedidosTipo[0].Suma }}
                </v-col>
                <v-col cols="4" class="text-right">
                </v-col>
            </v-row>
            <div v-if="report.pedidosTipo[0] && report.pedidosTipo[0]._id === 'takeaway'"
                class="report-card__separator" />

            <v-row v-if="report.pedidosTipo[1] && report.pedidosTipo[1]._id === 'delivery'" class="report-card__line">
                <v-col cols="4">
                    Pedidos enviados
                </v-col>
                <v-col cols="4" class="text-center">
                    {{ report.pedidosTipo[1].Suma }}
                </v-col>
                <v-col cols="4" class="text-right">
                </v-col>
            </v-row>
            <div v-if="report.pedidosTipo[1] && report.pedidosTipo[1]._id === 'delivery'"
                class="report-card__separator" />
            <v-row v-if="report.pedidosTipo[0] && report.pedidosTipo[0]._id === 'delivery'" class="report-card__line">
                <v-col cols="4">
                    Pedidos enviados
                </v-col>
                <v-col cols="4" class="text-center">
                    {{ report.pedidosTipo[0].Suma }}
                </v-col>
                <v-col cols="4" class="text-right">
                </v-col>
            </v-row>
            <div v-if="report.pedidosTipo[0] && report.pedidosTipo[0]._id === 'delivery'"
                class="report-card__separator" />

        </v-card>

        <div class="report-title report-card__title">
            Detalle de pedidos
        </div>

        <v-card v-for="order in report.orders" :key="order._id" class="report-card report-card--content mb-4">
            <div class="report-card__order">
                <div>
                    <v-icon>mdi-calendar-clock</v-icon> {{ order.createdAt | formatDateShort }} - {{ order.createdAt |
                            formatTime
                    }}
                </div>
                <div>
                    <v-icon>mdi-account-circle</v-icon> {{ order.customerData.email }} - {{ order.customerData.phone }}
                </div>
            </div>
            <div v-if="order.items">
                <v-row v-for="item in order.items" :key="item._id" class="report-card__line--order">
                    <v-col cols="4">
                        {{ item.name }} x{{ item.amount }}
                    </v-col>
                    <v-col cols="4" class="text-center">
                    </v-col>
                    <v-col cols="4" class="text-right">
                        {{ item.price * item.amount | formatPrice }} €
                    </v-col>
                </v-row>
            </div>
            <div v-if="order.deals">
                <v-row v-for="deal in order.deals" :key="deal._id" class="report-card__line--order">
                    <v-col cols="4">
                        {{ deal.dealType.name }} x1
                    </v-col>
                    <v-col cols="4" class="text-center">
                    </v-col>
                    <v-col cols="4" class="text-right">
                        {{ deal.dealType.price | formatPrice }} €
                    </v-col>
                </v-row>
            </div>
            <div>
                <v-row class="report-card__line--order">
                    <v-col cols="4">
                        Precio añadido reparto
                    </v-col>
                    <v-col cols="4" class="text-center">
                    </v-col>
                    <v-col cols="4" class="text-right">
                        {{ order.deliveryPrice | formatPrice }} €
                    </v-col>
                </v-row>
            </div>

            <div class="report-card__separator" />

            <div>
                <v-row class="report-card__line--order">
                    <v-col cols="4">
                        Subtotal
                    </v-col>
                    <v-col cols="4" class="text-center">
                    </v-col>
                    <v-col cols="4" class="text-right">
                        {{ order.orderPrice | formatPrice }} €
                    </v-col>
                </v-row>
            </div>
            <div v-if="order.deliveryCost">
                <v-row class="report-card__line--order">
                    <v-col cols="4">
                        Coste reparto
                    </v-col>
                    <v-col cols="4" class="text-center">
                    </v-col>
                    <v-col cols="4" class="text-right">
                        - {{ order.delivered_by !== 'local' ? order.deliveryCost : 0 | formatPrice }} €
                    </v-col>
                </v-row>
            </div>
            <div>
                <v-row class="report-card__line--order">
                    <v-col cols="4">
                        Comisión stripe
                    </v-col>
                    <v-col cols="4" class="text-center">
                    </v-col>
                    <v-col cols="4" class="text-right">
                        - {{ (0.014 * order.orderPrice) + 0.25 | formatPrice }} €
                    </v-col>
                </v-row>
            </div>

            <div class="report-card__separator" />
            <div>
                <v-row class="report-card__line--order">
                    <v-col cols="4">
                        Beneficio neto
                    </v-col>
                    <v-col cols="4" class="text-center">
                    </v-col>
                    <v-col cols="4" class="text-right">
                        {{ ((order.orderPrice - ((order.delivered_by !== 'local' ? order.deliveryCost : 0) || 0) -
                                ((0.014 * order.orderPrice) + 0.25)) * 100) / order.orderPrice | formatPrice
                        }} %
                    </v-col>
                </v-row>
            </div>
            <div>
                <v-row class="report-card__line--order">
                    <v-col cols="4">
                        <strong> TOTAL </strong>
                    </v-col>
                    <v-col cols="4" class="text-center">
                    </v-col>
                    <v-col cols="4" class="text-right">
                        <strong> {{ (order.orderPrice - ((order.delivered_by !== 'local' ? order.deliveryCost : 0) || 0) -
                                ((0.014 * order.orderPrice) + 0.25)) | formatPrice
                        }} € </strong>
                    </v-col>
                </v-row>
            </div>

        </v-card>


        <div class="report-title report-card__title">
            Resumen liquidación
        </div>
        <v-card class="report-card report-card--content mb-5">
            <v-row class="report-card__line">
                <v-col cols="4">
                    Total recaudado
                </v-col>
                <v-col cols="4" class=" text-center ">
                </v-col>
                <v-col cols="4" class=" text-right">
                    {{ this.costeTotal | formatPrice }} €
                </v-col>
            </v-row>

            <v-row class="report-card__line">
                <v-col cols="4">
                    Coste reparto
                </v-col>
                <v-col cols="4" class=" text-center ">
                </v-col>
                <v-col cols="4" class=" text-right">
                    - {{ this.enviosTotal | formatPrice }} €
                </v-col>
            </v-row>

            <v-row class="report-card__line">
                <v-col cols="4">
                    Comisiones Stripe
                </v-col>
                <v-col cols="4" class=" text-center ">
                </v-col>
                <v-col cols="4" class=" text-right">
                    - {{ this.comisionTotal | formatPrice }} €
                </v-col>
            </v-row>

            <div class="report-card__separator" />

            <v-row class="report-card__line">
                <v-col cols="4">
                    Beneficio neto
                </v-col>
                <v-col cols="4" class="text-center">
                </v-col>
                <v-col cols="4" class="text-right">
                    {{ ((this.costeTotal - this.enviosTotal - this.comisionTotal) * 100) / this.costeTotal |
                            formatPrice
                    }} %
                </v-col>
            </v-row>

            <v-row class="report-card__line">
                <v-col cols="4">
                    <strong> TOTAL </strong>
                </v-col>
                <v-col cols="4" class=" text-center ">
                </v-col>
                <v-col cols="4" class=" text-right">
                    <strong> {{ this.costeTotal - this.enviosTotal - this.comisionTotal | formatPrice }} € </strong>
                </v-col>
            </v-row>
        </v-card>

    </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { FETCH_DETAILED_REPORT } from "@/core/services/store/reports.module";


export default {
    name: "DetailedReport",
    components: {
    },
    props: {
        id: {
            required: true,
            type: String
        },
        f_inicio: {
            required: true,
            type: String
        },
        f_fin: {
            required: true,
            type: String
        }
    },
    data: vm => ({
        fecha_inicio: new Date().toISOString().substr(0, 10),
        fecha_fin: new Date().toISOString().substr(0, 10),
        menu1: false,
        menu2: false,
        snackbar: {
            show: false,
            text: ''
        },
        tipo_informe: 1,
    }),
    mounted() {
        const { id, f_inicio, f_fin } = this
        const payload = { id, f_inicio, f_fin }
        this.$store.dispatch(FETCH_DETAILED_REPORT, payload)
            .then((res) => {
            })
        // this.$store.dispatch(GET_PRODUCTS_BY_OWNER_ID, this.$store.state.auth.user.seller._id)
        // .then((res)=>{
        // })                       
        // this.$store.dispatch('getSellerLogs', this.$store.state.auth.user.seller._id)
        // .then((res)=>{
        // })         

    },
    watch: {
        date(val) {
            this.dateFormatted = this.formatDate(this.date)
        },
    },
    created() {
    },
    computed: {
        computedDateFormatted() {
            return this.formatDate(this.date)
        },
        fecha_inicio_formatted() {
            return this.formatDate(this.fecha_inicio)
        },
        fecha_fin_formatted() {
            return this.formatDate(this.fecha_fin)
        },
        ...mapState({
            isFetching: state => state.reports.isFetching,
            report: state => state.reports.report,
            seller: state => state.auth.user.seller,
            email: state => state.auth.user.email,
        }),
        minCurrentMonth() {
            if (this.datesRange.length) {
                let current = new Date(this.datesRange[0])
                current.setDate(current.getDate() + 1)
                return current.toISOString().slice(0, 10)
            } else {
                return new Date().toISOString().slice(0, 10)
            }
        },
        maxDate() {
            return new Date().toISOString().slice(0, 10)
        },
        costeTotal() {
            let total = 0
            this.report.orders.forEach(order => {
                total = total + order.orderPrice
            })
            return total
        },
        enviosTotal() {
            let total = 0
            this.report.orders.forEach(order => {
                total = total + ((order.delivered_by !== 'local' ? order.deliveryCost : 0) || 0)
            })
            return total
        },
        comisionTotal() {
            let total = 0
            this.report.orders.forEach(order => {
                total = total + ((0.014 * order.orderPrice) + 0.25)
            })
            return total
        }
    },
    methods: {

        openPrint() {
            window.print()
        },

        formatDate(date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
        parseDate(date) {
            if (!date) return null

            const [month, day, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        generar() {
            // console.log('Tipo de informe: ' + this.tipo_informe)
            // console.log('Fecha incio: ' + this.fecha_inicio)
            // console.log('Fecha fin: ' + this.fecha_fin)
        },

    }

}
</script>
<style>
.timeline-item {
    min-height: 28px !important;
}
</style>